import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import logo from "../images/SSD-logo.png"
import menuIcon from "../images/icons/menu-icon.svg"
import phoneIcon from "../images/phone.png"
import localtionIcon from "../images/map-location.png"

export interface HomeHeaderComponentProps {}
declare var require: any

const HomeHeaderComponent: React.SFC<HomeHeaderComponentProps> = () => {

  const [menu, setMenu] = useState([
    { item: "Home", link: "/" },
    { item: "News", link: "/articles/" },
    { item: "About ", link: "/company-business/" },
    // { item: "お問合せ", link: "https://purekotony.typeform.com/to/TRatHf" },
    { item: "Contact Us", link: "https://purekotony.typeform.com/to/TRatHf" },
  ])
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  useEffect(() => {
    const HEADER_ELEMENT = document.querySelector(".header-mobile")
    const HEADER_MENU = document.querySelector(".header_container")
    const HEADER_STICKY = document.querySelector(".container-menu-stick")
    const mediaQuery = window.matchMedia("(max-width: 767px)").matches
    if (!mediaQuery) {
      HEADER_MENU?.classList.remove("hiddeMenu")
      window.addEventListener("scroll", e => {
        if (document.documentElement.scrollTop > 30) {
          HEADER_STICKY.classList.add("nav-bar-scroll")
        } else {
          HEADER_STICKY.classList.remove("nav-bar-scroll")
        }
      })
    } else {
      HEADER_MENU?.classList.add("hiddeMenu")
      HEADER_ELEMENT?.classList.remove("nav-bar-scroll")
    }
  })
  const [showMenu, setMenuActive] = useState(false)
  const [showHero, setHero] = useState(false)
  const handleShowMenu = () => {
    setMenuActive(!showMenu)
    const element = document.querySelector(".menu-element")
    const element1 = document.querySelector(".header-mobile")
    const element2 = document.querySelector(".hero_hover")
    const mediaQuery = window.matchMedia("(max-width: 767px)").matches
    
    if (!showHero && element2) {
      element2.classList.add("hiddeMenu")
      setHero(true)
    } else {
      if(element2){
        element2.classList.remove("hiddeMenu")
        setHero(false)
      }
    }

    if (mediaQuery) {
      if (!showMenu) {
        if (element.classList.contains("hiddeMenu")) {
          element.classList.remove("hiddeMenu")
          element.classList.add("showMenu")
        } else {
          element.classList.add("showMenu")
        }
      } else {
        element.classList.add("hiddeMenu")
        element.classList.remove("showMenu")
      }
      setTimeout(() => {
        element.classList.remove("hiddeMenu")
      }, 500)
    }
  }

  return (
    <>
      <header>
        <div className="header_container">
          <div className="header_logo">
            <a href="https://supersonicdesign.com/">
              <img src={logo} alt="ssd-logo" />
            </a>
          </div>
          <div className="header_menu">
            <ul>
              <li>
                <a href="https://supersonicdesign.com/articles">NEWS</a>
              </li>
              <li>
                <a href="https://supersonicdesign.com/company-business">
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  href="https://purekotony.typeform.com/to/TRatHf"
                  target="_blank"
                  className="button-contact"
                >
                  CONTACT US
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="header-mobile">
          <Link to="/">
            <img
              className="ssd-logo"
              src={logo}
              alt="SUPER SONIC DESIGN INC."
            />
          </Link>
          <div className="btn-menu" onClick={() => handleShowMenu()}>
            <img src={menuIcon} alt="Menu Icon" />
          </div>
        </div>
      </header>
      <div className="content-header__responsive item-header menu-element">
        <div className="content-header__responsive__column-reverse">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="content-header__location">
                  <p>
                    <img src={localtionIcon} alt="Location" />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://maps.google.com/?q=40.748902,-73.987014"
                    >
                      43 W33rd ST, # 202, New York, NY, USA, 10001
                    </a>
                  </p>
                  <p>
                    <img src={phoneIcon} alt="Contact" />
                    <a
                      href="tel: +1-646-894-0318"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +1-646-894-0318
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container-full container-menu-stick">
            <div className="container swap-header__menu">
              <div className="row content-header__menu">
                <div className="col-lg-4">
                  <Link
                    to="/"
                    className="ssd-logo-desktop"
                    onClick={() => handleShowMenu()}
                  >
                    <img src={logo} alt="SUPER SONIC DESIGN INC." />
                  </Link>
                </div>
                <div className="col-lg-8">
                  <ul>
                    {menu.map((item, i) => (
                      <li key={i}>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => handleShowMenu()}
                        >
                          {item.item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeHeaderComponent
