import { any } from "prop-types"
import React, { useEffect, useState } from "react"
import FooterComponent from "../components/FooterComponent"
import HomeHeaderComponent from "../components/HomeHeaderComponent"
import { Seo } from "../components/seo"

import hero from "../images/Hero-on-Mars.png"


import hero_mob from "../images/Hero-on-Mars_mobile.png"
import web from "../images/home-services/1.svg"
import app from "../images/home-services/2.svg"
import ec from "../images/home-services/3.svg"
import gd from "../images/home-services/4.svg"
import br from "../images/home-services/5.svg"
import osd from "../images/home-services/6.svg"
import vid from "../images/home-services/7.svg"
import pho from "../images/home-services/8.svg"
import cm from "../images/home-services/9.svg"
import snsm from "../images/home-services/10.svg"
import nclc from "../images/home-services/11.svg"
import dtrans from "../images/home-services/12.svg"


declare var require: any

const NewHome = () => {
  


  const [heroFont, setHeroFont] = useState(Number)
  const [heroline, setHeroLine] = useState(Number)
  const [herotop, setHeroTop] = useState(Number)
  const [heroFontm, setHeroFontM] = useState(Number)
  const [herolinem, setHeroLineM] = useState(Number)
  const [herotopm, setHeroTopM] = useState(Number)
  const [heroleftm, setHeroLeftM] = useState(Number)
  const [herom, setHeroM] = useState(false)
  useEffect(() => {
    let width1 = (window.innerWidth)
    if (width1 > 420) {
      setHeroM(false)
      setHeroFont(width1 - width1 * 0.96)
      setHeroLine(width1 - width1 * 0.946)
      setHeroTop(width1 - width1 * 0.9795)
    }else{
      setHeroM(true)
      setHeroFontM(width1 - width1 * 0.93)
      setHeroLineM(width1 - width1 * 0.9)
      setHeroTopM(width1 - width1 * 0.9736)
      setHeroLeftM(width1 - width1 * 0.9815)
    }

    function handleResize() {
      width1 = (window.innerWidth)
      if (width1 > 420) {
        setHeroM(false)
        setHeroFont(width1 - width1 * 0.96)
        setHeroLine(width1 - width1 * 0.946)
        setHeroTop(width1 - width1 * 0.9795)
      }else{
        setHeroM(true)
        setHeroFontM(width1 - width1 * 0.93)
        setHeroLineM(width1 - width1 * 0.9)
        setHeroTopM(width1 - width1 * 0.9736)
        setHeroLeftM(width1 - width1 * 0.9815)
      }
      setTimeout(() =>{
      },5000)
    }
    window.addEventListener("resize", handleResize)
  })
  return (
    <>
      <Seo
        title="Super Sonic Design INC | 会社・業務内容について"
        url="https://supersonicdesign.com/"
        description="弊社の業務内容や代表の鹿毛の起業した際のストーリーなどを動画でお話させていただきました。"
        image=""
        author=""
        keywords="Super Sonic Design INC | 会社・業務内容について"
      />

      <div className="home_content">
        {herom ? (
          <img src={hero_mob} alt="hero_home_mobile" id="hero_home_mobile" />
        ) : (
          <img src={hero} alt="hero_home" id="hero_home" />
        )}
        <div className="head_home">
          <HomeHeaderComponent />
        </div>

        {herom ? (
          <div className="hero_hover_mobile">
            <p
              className="hero_hover_mobile_text"
              style={{ fontSize: heroFontm, lineHeight: herolinem + "px", top: herotopm + "%", left: heroleftm + "%" }}
            >
              Creative and Tech <br />
              For You Anywhere
            </p>
          </div>
        ) : (
          <div className="hero_hover" style={{ top: herotop + "%" }}>
            <p
              className="hero_hover_text"
              style={{ fontSize: heroFont, lineHeight: heroline + "px" }}
            >
              Creative and Tech <br />
              For You Anywhere
            </p>
            <a href="https://purekotony.typeform.com/to/TRatHf" target="_blank">
              CONTACT US
            </a>
          </div>
        )}

        <div className="home_about">
          <p className="home_about_title">About</p>
          <p className="home_about_text">
            ニューヨークのデザイン会社のスーパーソニックデザイン株式会社です。ブランディング、映像制作、ウェブサイトの作成、アプリの開発まで様々なクリエイティブ業務を行なっております。ここ最近ではDXと呼ばれるデジタルトランスフォーメーションの業務も多く携わっております。コロナ禍で大変な時代ですが一緒にがんばりましょう！
          </p>
          <a href="https://supersonicdesign.com/company-business">Read More</a>
        </div>

        <div className="home_services">
          <p className="home_services_title">Services</p>
          <p className="home_services_text mb40">
            弊社がご提供しているクリエイティブ、IT関連のサービスの一覧です。
          </p>
          <div className="home_services_columns">
            <div className="home_services_column mr60">
              <img src={web} alt="web" />
              <p className="home_services_subtitle">Web Design</p>
              <p className="home_services_texts">
                ウェブデザイン、ウェブアプリケーション開発、ワードプレス、LP制作、EC、オークションサイト開発等
              </p>
            </div>
            <div className="home_services_column mr60">
              <img src={app} alt="app" />
              <p className="home_services_subtitle">App Development</p>
              <p className="home_services_texts">
                React
                Native、業務支援アプリ開発、ウェブアプリ開発、API開発、ECアプリ、オーダーアプリ、スケジュール管理アプリ等
              </p>
            </div>
            <div className="home_services_column">
              <img src={ec} alt="ec" />
              <p className="home_services_subtitle">EC Development</p>
              <p className="home_services_texts">
                完全オリジナル特殊ECサイト作成、Woocommerce、ShopifyなどのECプラットフォームを使ったEC作成
              </p>
            </div>
          </div>
          <div className="home_services_columns">
            <div className="home_services_column mr60">
              <img src={gd} alt="gd" />
              <p className="home_services_subtitle">Graphic Design</p>
              <p className="home_services_texts">
                ロゴ、イラスト、パッケージ、車用ラッピング、カタログ、名刺、看板デザイン等
              </p>
            </div>
            <div className="home_services_column mr60">
              <img src={br} alt="branding" />
              <p className="home_services_subtitle">Branding / PR</p>
              <p className="home_services_texts">
                ブランドスタイルガイド作成、コーポレーションアイデンティティ作成、ブランディング支援等、PR支援、
              </p>
            </div>
            <div className="home_services_column">
              <img src={osd} alt="osd" />
              <p className="home_services_subtitle">
                Original Service Development{" "}
              </p>
              <p className="home_services_texts">
                超高速ウェブサイト、リアルタイム高速通信サービス、オリジナルSNSサイト、
                GatsbyJS、Strapi、Netlify、TypeScript、Google Firebase、AWS
              </p>
            </div>
          </div>
          <div className="home_services_columns">
            <div className="home_services_column mr60">
              <img src={vid} alt="vid" />
              <p className="home_services_subtitle">Videography</p>
              <p className="home_services_texts">
                インタビュー動画作成、Reel用動画作成、商品説明動画作成、シナリオ作成、アニメーション作成、多言語声優手配等
              </p>
            </div>
            <div className="home_services_column mr60">
              <img src={pho} alt="pho" />
              <p className="home_services_subtitle">Photography</p>
              <p className="home_services_texts">
                商品撮影、撮影指示、広告用撮影ディレクション、レタッチ、GIF作成等
              </p>
            </div>
            <div className="home_services_column">
              <img src={cm} alt="cm" />
              <p className="home_services_subtitle">Contents marketing (SEO)</p>
              <p className="home_services_texts">
                多言語コンテンツ作成支援、SEO対策、戦略と運営代行、SEMとの連携支援
              </p>
            </div>
          </div>
          <div className="home_services_columns">
            <div className="home_services_column mr60">
              <img src={snsm} alt="snsm" />
              <p className="home_services_subtitle">SNS marketing</p>
              <p className="home_services_texts">
                Instagram運営代行、TikTok運営代行、SNS広告支援、NY現地での撮影からSNS運用までのコンテンツ一括管理体制
              </p>
            </div>
            <div className="home_services_column mr60">
              <img src={nclc} alt="nclc" />
              <p className="home_services_subtitle">No Code/ Low Code</p>
              <p className="home_services_texts">
                ノーコードツールを業務に使えるようにする支援、ローコードとAPI連携での多彩な業務アプリ作成、
                AI活用等
              </p>
            </div>
            <div className="home_services_column">
              <img src={dtrans} alt="dtrans" />
              <p className="home_services_subtitle">Digital Transformation</p>
              <p className="home_services_texts">
                業務のデジタル化、データベース構築と運用支援、データの有効活用支援、クラウド活用支援等
              </p>
            </div>
          </div>
          <a href="https://purekotony.typeform.com/to/TRatHf" target="_blank">
            CONTACT US
          </a>
        </div>

        <div className="home_map">
          <div className="home_map_columns">
            <div className="home_map_column40">
              <p className="home_map_title">SUPER SONIC DESIGN INC.</p>
              <p className="home_map_text">
                43 W33rd ST, #202, New York, NY, USA, 10001 
                <br />
                Phone:+1-646-894-0318 <br />
                Email:kage@supersonicdesign.com <br />
                Website:supersonicdesign.com
              </p>
            </div>
            <div className="home_map_column60">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.597933582118!2d-73.98930858507882!3d40.74887184329693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259af84014cc1%3A0x1e2478b0a7d36b53!2sSuper%20Sonic%20Design%2C%20Inc!5e0!3m2!1sen!2sbo!4v1643401698265!5m2!1sen!2sbo"
                width="100%"
                height="350"
                style={{ border: "0" }}
                allowFullScreen={true}
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  )
}
export default NewHome
function handleResize(arg0: string, handleResize: any) {
  throw new Error("Function not implemented.")
}
